.setting {
  z-index: 6;
}

// .landscape {
  .setting {
    position: absolute;
    top: 0rem;
    right: 0rem;
    color: #4b4b4b;
    // transform: scale(1.5);
    .volume-range {
      position: relative;
      // top: -16rem;
      // left: -10rem;
      z-index: 7;
      width: 100%;
    }
    .setting-bg-range {
      position: absolute;
        top: -0.3rem;
    }
    .setting-volume {
      position: absolute;
      right: 2.5rem;
      top: 4rem;
      z-index: 15;
      width: 10rem;
      padding-inline: 2rem;

      label {
        justify-content: space-between;
        width: 90%;
      }
    }
    // .setting-sound-item{
    //   margin-top: -19rem;
    //   width: 15rem;
    //   font-size: 1.7rem;
    //   position: absolute;
    //   &>div{
    //     img {
    //       margin-left: 1.5rem;
    //       margin-right: 1.5rem;

    //     }
    //   }

    //   &>div:not(:first-child):hover {
    //     background-color: rgba(179, 120, 38, 0.3);
    //     // filter: invert(1);
    //   }

    // }


    .setting-list {
      margin-top: -3.7rem;
      // display: flex;
      // width: 16rem;
      // background-color: red;
      margin-inline: 1rem;
      display: flex;
      justify-content: space-around;
      // max-width: 20rem;
      align-items: center;
      // gap: 5rem;
      .list-item:first-child {
        width: 6rem;
        font-size: 2rem !important;
      }
      .list-item {
        img {
          width: 2rem;
        }
        .item-text {
        }
      }
      
    }
    .history-view_all {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .history-view_all-bg {
        margin-top: 1rem;
      }
      .view_all-text {
        color: white;
        margin-top: -2.75rem;
        font-size: 2rem;
      }
    }

    .info-period{
      font-size: 1.5rem !important;
      font-weight: 700 !important;
      color: #59370C;
      // max-width: 5rem;
    }

  }
// }
.iPhone .portrait {
  .setting-list {
    margin-top: -3.5rem !important;
  }
}
.portrait {
  .setting {
    position: absolute;
    top: 45rem;
    right: 25rem;
    transform: scale(3.5);
    .setting-list {
      margin-top: -3.2rem;
      // display: flex;
      // width: 16rem;
      // background-color: red;
      // margin-left: 8rem;
      align-items: center;
      margin-inline: unset;
  
      .list-item:first-child {
        width: 6rem;
      }
      .list-item {
        margin-top: 0.65rem;
        display: flex;
  
        img {
          width: 1rem;
        }
      }
    }
  
    .setting-volume {
      top: 0rem;
      left: 6.5rem;
      transform: scale(0.5);
    }
  }

  .header-info {
    // margin-top: 3vh;
    // border-radius: 3vh;
    // height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
  }
  
  .header-info-content {
    display: flex;
    align-items: center;
    // gap: 0.5rem;
  
  }
  
  .header-info-icon {
    width: 0.5rem !important;
    height:auto;
    padding-right: 0.5rem;
    // width: auto;
  }
  
  .header-info-user-value,
  .header-info-balance-value {
    font-size: 0.6rem;
    font-weight: 700;
  
  
  }
  
  .header-info-balance-value {
    // color: $amount;
  }
  
  
}

