.result-error {
  background-color: rgba($color: #000000, $alpha: 0.6);

    .multiplier-label {
      color: red;
      font-size: 3rem !important;
    }
  
    .multiplier-label-message {
      margin-top: 0.25rem;
      font-weight: 500;
      font-size: 2rem;
      z-index: 8;
      color: #59370C;
      max-width: 35rem;
      text-align: center;
    }
  }
  .landscape {
    .result-error {
      position: fixed;
      top: 0;
      left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 13;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .black{
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 12;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      *{
        z-index: 13;
      }
      .result-error-bg {
        transform: scale(3);
        margin-top: -3rem;
      }
      .result-error-title {
        display: flex;
        // background-color: #000000;
        justify-content: space-between;
        transform: scale(2);
        position: absolute;
        top: 10rem;
        width: 25.5rem;
  
        .title-bet {
          font-size: 1.8rem;
          font-weight: 500;
          margin-left: 0.5rem;
  
          span {
            margin-left: 0.5rem;
          }
        }
      }
      .multiplier {
        position: absolute;
        top: 22rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .multiplier-label {
          margin-top: 6.25rem;
          font-weight: 500;
          font-size: 3rem;
          z-index: 8;
          color: red;
        }
        .multiplier-bg {
         
          
        }
      }
     
    }
  }
  
  
  .portrait {
    .result-error {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 13;
      width: 100%;
      height: 135%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1);
      // background-color: rgba($color: #000000, $alpha: 0.6);

      .black{
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 12;
        width: 150%;
        height: 200%;
        top: 0;
        left: -10%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      *{
        z-index: 13;
      }
      .result-error-bg {
        // margin-top: -5rem;
        transform: scale(4);

      }
  
      .result-error-title {
        display: flex;
        // background-color: #000000;
        justify-content: space-between;
        position: absolute;
        top: 75rem;
        width: 22rem;
        transform: scale(3);
  
        .title-bet {
          font-size: 1.8rem;
          font-weight: 500;
          margin-left: 0.5rem;
  
          span {
            margin-left: 0.5rem;
          }
        }
      }
  
      .multiplier {
        position: absolute;
        top: 86rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .multiplier-label {
          margin-top: 8.25rem;
          font-weight: 500;
          font-size: 5rem;
          z-index: 8;
        }

        .multiplier-label-message {
          font-size: 3rem;
          margin-top: 0.25rem;
          
        }
       
        .multiplier-bg {
          width: 5rem;
          height: 4.2rem;
          display: flex;
          border-radius: 0.5rem;
          filter: contrast(0.9);
          box-shadow: 0 0.2rem 0.2rem 0.2rem rgba(0,0,0,0.1), inset 0rem 0.3rem 0.5rem 0rem rgba($color: #fff, $alpha: 0.3), inset 0rem -0.3rem 0.3rem 0rem rgba($color: #fff, $alpha: 0.1);
          
        }
      }
     
    }
  }
  