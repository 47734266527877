.bet-wrapper {

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    -webkit-appearance: none;
  }
}

.mode-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 10;
  margin-left: -1.25rem;
  // background-color: rgba($color: #000000, $alpha: 1.0);
}

.form-group.bet-form-on-win.mines-gems {
  label {
    margin-top: -1.1rem !important;
  }

  img {
    margin-top: 0.4rem;
  }

  input {
    top: 1.5rem !important;
  }
}

// .landscape {
.bet-wrapper {
  position: fixed;
  top: -0.5rem;
  left: 0rem;

  .bet {
    position: relative;
    color: white;
    height: 6 0rem;
    display: flex;
    justify-content: center;

    .disable {
      position: absolute;
      height: 75%;
      width: 100%;
      background-color: rgba($color: #000000, $alpha: 0);
      z-index: 2;
    }

    .bet-bg {
      // height: 2rem;
    }

    .bet-form.manual {}

    .bet-form {
      height: max-content;
      position: absolute;
      width: max-content;
      top: 4rem;
      // margin-left: 2rem;
      // display: flex;
      flex-direction: column;
      height: 56.5rem;

      // background-color: rgba($color: #000000, $alpha: 0.5);
      .bet-form-ball .tool-bg-dropdown {
        display: none;
      }

      .form-group.mode {
        width: 22.5rem;
        margin-left: 2rem;

        .mode-container {
          position: absolute;
          margin-left: 1.5rem;
          font-weight: 700;
          font-size: 2.5rem;

          img {
            position: absolute;
            margin-top: 0.25rem;
            animation: slide-in 0.2s forwards;

          }

          img.auto {
            animation: slide-out 0.2s forwards;
          }

          @keyframes slide-in {
            0% {
              transform: translateX(85%);

            }

            100% {
              transform: translateX(-10%);
            }
          }

          @keyframes slide-out {

            0% {
              transform: translateX(-10%);
            }

            100% {
              transform: translateX(85%);

            }
          }

          span {
            top: -0.1rem;
            position: relative;
            left: -1.5rem;
            // margin-right: 4.25rem;
            color: #2c2214;
            padding-left: 1.5rem;
            padding-right: 2.4rem;
            // background-color: rgba($color: #000000, $alpha: 0.5);
            text-shadow: 0px 3px 10px rgba(255, 255, 255, 0.7);
          }

          span:nth-child(3) {
            padding-left: 2rem;
            // background-color: rgba($color: #000000, $alpha: 0.5);

          }
        }

      }

      .form-group-dropdown {
        input {
          margin-top: 0.1rem;
        }

        .bet-form-tool-list.dropdown.disabled {
          opacity: 50%;
        }
      }

      .form-group.form-group-dropdown.bet-form-mines-manual {
        width: 12rem;
        height: unset;
        margin-top: unset;
        flex-direction: column-reverse;

        input {
          // margin-top: -1rem;
        }

        // img
        .bet-form-tool-list {
          top: 2.05rem !important;
          right: 0.8rem !important;
        }

        .dropdown-select {
          width: 88%;
          top: 4.4rem;
          left: 0.5rem;
        }
      }

      .form-group {
        margin-top: -1rem;
        display: flex;
        flex-direction: column;
        width: 25.5rem;
        // margin-top: 3rem 0;
        position: relative;
        height: 8rem;
        // margin-bottom: 2.5rem;


        input {
          margin-left: 2rem;
          margin-top: 0.3rem !important;
        }

        .dropdown-select::-webkit-scrollbar {
          // display: none;
        }

        .dropdown-select {

          max-height: 22rem;
          width: 93%;
          // height: 5rem;
          overflow-y: scroll;
          top: 7.92rem;
          left: 0.9rem;
          position: absolute;
          background-color: #987447;
          // border-radius: 0.75rem;
          z-index: 1;
          font-weight: 500;
          font-size: 1.5rem;
          // overflow: hidden;
          // box-shadow: inset 0 0 0.05rem #000;
          z-index: 100;

          .select-item {
            width: 100%;
            height: 3rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &>* {
              padding-left: 1rem;
            }
          }

          .select-item:hover {
            background-color: #43a9ff;
          }
        }

        .bet-form-tool-list {
          z-index: 2;
          position: absolute;
          right: 0.5rem;
          top: 5.5rem;
          height: 2rem;
          display: flex;
          flex-direction: row;
          width: max-content;

          .tool-item {
            margin: 0 0rem;
            min-width: 2rem;
            text-align: center;
            justify-content: center;

            div {
              margin-top: -0.4rem;
              min-height: 2.5rem;
              justify-content: space-around;
              align-items: center;
            }

            span {
              display: block;
              font-weight: 600;
              position: absolute;
              padding: 0.6rem 0.75rem;
              margin: 0.125rem 0.125rem;
              // background-color: rgba($color: #000000, $alpha: 0.5);
            }

            .tool-bg-dropdown {
              margin: 0.25rem 0.15rem;
            }
          }

        }

        input:focus {
          appearance: unset;
        }

        input {
          font-weight: 500;
          font-size: 1.75rem;
          color: white;
          width: 16rem;
          background-color: transparent;
          border: none;
          height: 3rem;
        }

        label {
          margin-top: -1rem;
          margin-left: 2rem;
          font-weight: 650;
          font-size: 1.7rem;
          // height: 2rem;
          color: #8d682e;
          text-shadow: 0px 3px 1px rgba(255, 255, 255, 0.9);
        }

        input {
          z-index: 2;
        }

        .bet-bg-input {
          position: relative;
          top: 5rem;
          z-index: 1;
          width: fit-content;
        }
      }

      .form-group.bet-form-bet.total-profit {
        margin-top: -5rem;
      }

      .form-group.start-auto.random {
        margin-top: 1rem;
        // height: fit-content;
      }

      .form-group.start-auto.cash_out {
        margin-top: 1rem;
        // height: fit-content;
      }


      .form-group.start-auto {
        margin-top: 1.5rem;
        text-align: center;
        height: fit-content;

        span {
          margin-top: -4rem;
          // margin-left: 6rem;
          font-size: 2rem;
          font-weight: 700;
        }
      }

      .form-group.bet-form-on-win.mines-gems {
        .input {
          &>div {
            position: relative;
            margin-right: 2rem;
            display: flex;
            flex-direction: column;

            input {
              position: absolute;
              // background-color: rgba($color: #000000, $alpha: 1.0);
              // margin-top: -8rem;
              width: 7rem;
              left: 0rem;
              top: 1.25rem;
            }

            .bet-form-tool-list {
              top: 2.25rem;
              right: 0.5rem;
            }
          }
        }
      }

      .form-group.bet-form-on-win.on-loss {
        margin-top: 2rem;
      }

      .form-group.bet-form-on-win {
        margin-top: 2rem;

        .input {
          display: flex;
          justify-content: space-between;

          &>div {

            // background-color: rgba($color: #000000, $alpha: 0.5);
            margin-right: 0.5rem;

            .bet-bg-input {
              position: unset;
            }

            input {
              position: absolute;
              // background-color: rgba($color: #000000, $alpha: 1.0);
              // margin-top: -8rem;
              width: 5rem;
              left: 15rem;
              top: 1.5rem;
            }

            .bet-form-tool-list {
              top: 2.25rem;

            }

          }
        }

        .switch-container {
          position: absolute;
          margin-left: 0.5rem;
          margin-top: -3.5rem;
          font-weight: 600;
          font-size: 1rem;

          img {
            position: absolute;
            margin-top: 0.5rem;
            left: 0.5rem;
            animation: slide-in 0.2s forwards;

          }

          img.increase-by {
            left: 1.2rem;
            animation: slide-out 0.2s forwards;
          }

          span {
            font-size: 1.2rem;
            top: 0.6rem;
            position: relative;
            left: 1rem;
            // margin-right: 3rem;
            padding-inline: 0.9rem;
            color: #2c2214;
            text-shadow: 0px 3px 10px rgba(255, 255, 255, 0.7);
          }

          span:nth-child(3) {
            padding-left: 1.8rem;
            // background-color: rgba($color: #000000, $alpha: 0.5);

          }
        }

        .bet-bg-input8-rounded {
          width: fit-content;
        }

        .bet-bg-input {}
      }

      .form-group.bet-form-bet {
        input {
          margin-top: 0.2rem;
        }

        .bet-form-tool-list {
          position: absolute;
          top: 4rem;
          right: -1rem;
          font-size: 1.8rem;

          .x2 {
            margin-left: 0.5rem;
          }
        }
      }


      .form-group.stop-loss {
        margin-bottom: 2rem;
      }
    }

    // .bet-button:active {
    //   margin-top: -11.5rem;
    // }

    .bet-button.center {
      margin-left: 9rem;
    }

    .bet-button.auto {
      margin-left: 3rem;
    }

    .bet-button.non-auto {
      margin-left: 15rem;
      // margin-top: 10rem;
    }

    .bet-button {
      position: absolute;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: -10rem;
      margin-left: -3rem;
      cursor: pointer;

      .bet-button-bg {
        margin-top: 1rem;
        width: 10rem;

      }

      .button-text {
        color: black;
        margin-top: -3.8rem;
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }
}

.landscape {
  .bet-wrapper {
    .bet {
      .bet-bg {
        height: 68rem;
      }

      .bet-form {
        // margin-left: 3rem;
      }
    }

    .header-info {
      // margin-top: 3vh;
      // border-radius: 3vh;
      width: 80%;
      align-self: center;
      // margin-left: 10rem;
      // transform: scale(3.5);
      position: absolute;
      padding: 0.5rem 0.5rem;
      border-radius: 0.3rem;
      bottom: 5rem;
      background: rgba(91, 53, 0, 0.4);
      display: flex;
      flex-direction: column;
      justify-content: space-around;

    }

    .header-info-content {
      display: flex;
      align-items: center;
      // gap: 0.5rem;

    }

    .header-info-icon {
      width: 2rem !important;
      height: auto;
      padding-right: 0.5rem;
      // width: auto;
    }

    .header-info-user-value,
    .header-info-balance-value {
      font-size: 2rem;
      font-weight: 700;

      color: rgba(91, 53, 0, 1);

    }

    .header-info-balance-value {
      // color: $amount;
    }



  }
}

.portrait {
  .bet-wrapper {
    .bet-bg {
      width: 30rem;
    }

    .header-info {
      display: none;
    }
    z-index: 10;
    position: fixed;
    top: 140rem;
    left: 20rem;
    transform: scale(2.5);

    .bet-form {
      top: 1.6rem;
      height: 38rem;

      .form-group.bet-form-bet.total-profit {
        margin-top: -5rem;
      }
    }
  }
}


// .safari {
//   .form-group
//    {
//   input {
//     margin-top: 1.05rem !important;
//   } 
// }
// .mode span {
//   top: 0.5rem !important;
//   left: -2rem !important;
//   padding-left: 0 !important;

// }
// }

// @media (max-width: 770px) {
//   .landscape .bet .bet-form .form-group input {
//     margin-top: unset;
//   }
// }