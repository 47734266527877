.td-date,
.td-betperball {
  width: 15%;
  text-align: center;
}

.td-betperball {
  text-align: left;

}

.td-period {
  text-align: center;
  width: 10%;
}

.td-mines {
  width: 5%;
  text-align: center;

}

.td-totalbet,
.td-multiplier {
  width: 15%;
  text-align: center;
  // padding-left: 0.5rem;
}

.td-payout {
  width: 20%;
  text-align: center;
  // padding-left: 0.5rem;
}

.td-icon {
  width: 5%;
  // font-size: 3rem;
  // color: rgb(58, 33, 123);
}

.history {
  font-weight: 600;

}

.table-head {
  color: white;

}

.table-body {
  color: #000;

  // box-shadow: inset 0 0.1rem 0.5rem rgba($color: #000000, $alpha: 0.5), 0 0.1rem 0.5rem rgba($color: #000000, $alpha: 0.5);
  tr:nth-child(odd) {
    height: 5rem;
  }


}

tbody {
  li{
    padding-top: 1rem;
    // border-top: 2px solid rgb(230, 217, 195);
  }
}


.landscape {
  .retable {
    height: 17rem;
    .game-container{
      transform: scale(0.25);
  // position: absolute;
      .game-wrapper {
    
        top: -48rem !important;
    
      }
    }
  }

}
.retable {
  display: flex;
  // margin-top: -1px;

  width: 98%;
  .game-container {
    transform: scale(0.5);
    width: 19rem;
    height: 30rem;
margin: 0 auto;
  }

  .game-wrapper {
    background-color: #000;

    left: -33rem !important;
    top: -25rem !important;
    // left: 0 !important;
    // top: 0 !important;
    // width: unset !important;
    // height: unset !important;
    padding: 5rem 2rem 7rem 5rem;
    position: relative !important;
  }

  // table {
  //   color: white;

  //   tr,
    td {
      // height: 5rem;
      img {
        margin-top: -1rem;
      }
    }

    


  border-radius: 1.5rem;
  overflow: hidden;

  .head-list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 5rem;

    td {
      display: flex;
      justify-content: center;
      flex-direction: column;
      vertical-align: middle;

    }
  }
}

.landscape {
  .history {

    .table-container {
      position: fixed;
      top: 0;
      left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 6;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .black {
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.6);
        z-index: 6;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      * {
        z-index: 7;
      }

      .table-bg {
        margin-top: -3rem;
      }

      .table-title {
        position: absolute;
        top: 2.5rem;
        display: flex;
        width: 57rem;
        // background-color: black;
        justify-content: space-between;

        .title {
          font-size: 1.6rem;
        }
      }

      .table {
        position: absolute;
        top: 10rem;
        font-size: 1.1rem;

        .table-head {
          margin-left: 1.5rem;
          width: 45rem;
          height: 4rem;
          // overflow-x: visible;
          display: flex;
          justify-content: center;

          .table-head-scroll {
            width: 40rem;
            background-repeat: no-repeat;
            height: 100%;
            // overflow-x: scroll;
            margin-left: -1.25rem;

            .head-list {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100%;

              .list-item {
                text-align: center;
              }
            }
          }
        }

        .table-body::-webkit-scrollbar,
        .table-head-scroll::-webkit-scrollbar {
          display: none;
        }

        .table-body {
          margin-top: 2rem;
          margin-left: 2.5rem;
          width: 43rem;
          height: 40rem;
          // border-radius: 1rem;
          overflow-y: scroll;
          overflow-x: hidden;

          table {
            width: 100%;
            text-align: center;
            border-collapse: collapse;

          }
        }
      }
    }

    .slider-x {
      display: none;
    }

    .slider-y {
      position: absolute;
      transform: rotate(90deg);
      right: -17.1rem;
      top: 20rem;
      width: 33rem;
    }

    .rangeslider {
      margin: 0 auto;
      padding: 20px 0;
      width: 100%;
    }

    input[type="range"] {
      background: #2b126a;
      border-bottom: 1px solid #4b4b4b;
      border-top: 1px solid #060606;
      // height: 15px;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 12px;
    }

    .rangeslider,
    input::-webkit-slider-thumb {
      background: #a287e5;
      // border: 1px solid #1a1a1a;
      cursor: pointer;
      height: 20px;
      text-indent: -9999px;
      width: 180px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.2) inset;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 10px;
      // margin-bottom: 0.1rem;
    }

    input[type="range"]:focus {
      outline: none;
    }
  }
}




.portrait {

  .history {
    z-index: 11;
    .table-container {
      position: fixed;
      top: 30rem;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.6);
      z-index: 13;
      width: 100%;
      height: 100%;
      padding-bottom: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: scale(1.15);

      .black {
        position: fixed;
        background-color: rgba($color: #000000, $alpha: 0.4);
        z-index: 6;
        width: 100%;
        height: 300%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        display: none;
      }

      * {
        z-index: 13;
      }

      .table-bg {
        margin-top: -3rem;
        transform: scale(1.5);
      }

      .table-title {
        position: absolute;
        top: 24rem;
        display: flex;
        width: 37rem;
        // background-color: black;
        justify-content: space-between;
        transform: scale(1.5);

        .title-bet-icon {
          padding-left: 1.5rem;
          width: 1rem;
          filter: grayscale(100%);
        }

        .title {
          font-size: 1.6rem;
          // color: #000;
          color: #59370C;
          filter: grayscale(100%);

        }
      }

      .table {
        position: absolute;
        top: 25.5rem;
        font-size: 1.5rem;
        transform: scale(0.8);

        .table-head {
          width: 56rem;
          height: 5rem;
          color: white;
          // overflow-x: visible;
          display: flex;
          justify-content: center;
          
          .table-head-scroll {
            margin-left: -5rem;
            width: 50rem;
            background-repeat: no-repeat;
            height: 100%;
            // overflow-x: scroll;

            .head-list {
              align-items: center;
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100%;

              .list-item {
                text-align: center;
              }
            }
          }
        }

        .table-body::-webkit-scrollbar,
        .table-head-scroll::-webkit-scrollbar {
          display: none;
        }

        .table-body {
          margin-top: 1rem;
          margin-left: 0.5rem;
          // background-color: #fff;
          width: 53rem;
          height: 83rem;
          // border-radius: 1rem;
          overflow-y: scroll;
          // overflow-x: scroll;

          &>table {
            width: 100%;
            text-align: center;
            border-collapse: collapse;

            .retable {

              .game-container {
                transform: scale(0.5);
                width: 53rem;
                height: 40rem;

                .game-wrapper {
                  left: -15rem !important;
                }
              }
            }
          }
        }
      }
    }

    .slider-y {
      position: absolute;
      transform: rotate(90deg);
      right: -17.1rem;
      top: 20rem;
      width: 33rem;
    }

    .rangeslider {
      margin: 0 auto;
      padding: 20px 0;
      width: 100%;
    }

    input[type="range"] {
      background: #2b126a;
      border-bottom: 1px solid #4b4b4b;
      border-top: 1px solid #060606;
      // height: 15px;
      width: 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 12px;
    }

    .rangeslider,
    input::-webkit-slider-thumb {
      background: #a287e5;
      border: 1px solid #1a1a1a;
      cursor: pointer;
      height: 20px;
      text-indent: -9999px;
      width: 180px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.2) inset;
      -moz-appearance: none;
      -webkit-appearance: none;
      border-radius: 10px;
      // margin-bottom: 0.1rem;
    }

    input[type="range"]:focus {
      outline: none;
    }
  }
}