



.landscape {
    .game-container {
        .game-wrapper {
            // background-color: rgba($color: #000000, $alpha: 0.5);
            position: absolute;
            left: 43rem;
            top: 2rem;
            width: 70rem;
            height: 62.5rem;
            transform: scale(0.9);

            .row {
                display: flex;
                flex-direction: row;
                width: 100%;
                // background-color: rgba($color: #000000, $alpha: 0.5);
                height: 10rem;
                margin-block: 2.5rem;

                .col {
                    height: 100%;
                    width: fit-content;
                    margin-inline: 2.5rem;
                    transform: scale(1.35);
                    // background-color: rgba($color: #000000, $alpha: 0.5);
                    overflow-y: hidden;

                    .chest {
                        // position: relative;
                        height: 100%;
                        // overflow-y: hidden;

                        .box {
                            // position: absolute;
                            width: 8.5rem;
                        }

                        .content {
                            position: relative;
                            width: 8.5rem;
                            transform: scale(0.75);

                        }





                    }

                    .chest .content.bomb {
                        top: -9.1rem;
                        // left: -8rem;
                    }
                }
            }
        }
    }
}

.portrait {
    .game-container {
        .game-wrapper {
            // background-color: rgba($color: #000000, $alpha: 0.5);
            position: fixed;
            left: 1.5rem;
            top: 48rem;
            width: 70rem;
            height: 62.5rem;
            transform: scale(0.9);

            .row {
                display: flex;
                flex-direction: row;
                width: 100%;
                // background-color: rgba($color: #000000, $alpha: 0.5);
                height: 10rem;
                margin-block: 3rem;

                .col {
                    height: 100%;
                    width: fit-content;
                    margin-inline: 2.5rem;
                    transform: scale(1.35);
                    // background-color: rgba($color: #000000, $alpha: 0.5);
                    overflow-x: hidden;

                    .chest {
                        // position: relative;
                        display: flex;
                        width: 100%;
                        // overflow-x: hidden;

                        .box {
                            // position: absolute;
                            width: 8.5rem;
                        }

                        .content {
                            position: relative;
                            width: 8.5rem;
                            transform: scale(0.75);
                        }

                    }


                    .chest .content.bomb {
                        // top: -9rem;
                        left: -8rem;
                    }
                }
            }
        }
    }
}

.chest {
    // filter: contrast(1);
    max-width: 8.5rem;


}

.chest.selected {
    filter: brightness(0.5);
}

.chest.shadowOpen {
    filter: brightness(0.3);
}

.chest.selected.open {
    filter: none !important;
}
// .manual {
//     .chestControl {
//         display: none;
//     }
    
//     .chestControl:checked + label > .chest {
//         filter: drop-shadow(0px 0px 10px #fff);
//     }
// }

.landscape {
    .game-container {
        .total-profit-auto {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 30%;
            z-index: 5;

            span {

                text-align: center;
            }
            canvas {
                margin-top: -45rem;
                margin-left: -30.5rem;
                transform: scale(1);

            }
            * {
                text-align: center;
                position: absolute;
                margin-top: 55%;
                margin-left: 27.5%;
                font-size: 3rem;
            }
        }
    }

}

.portrait {
    .game-container {
        .total-profit-auto {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 30%;
            z-index: 5;

            canvas {
                margin-top: -46.5rem;
                margin-left: -34.5rem;
                transform: scale(0.65);
            }

            span {
                // margin-top: 2rem;

                z-index: 2;
                text-align: center;
            }

            * {
                position: absolute;
                margin-top: -142.5rem;
                margin-left: 0.8rem;
                font-size: 3rem;
                transform: scale(1.5);
            }
        }
    }

}