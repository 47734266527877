/* thai */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/font/Athiti-ExtraLight.ttf") format('ttf');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* vietnamese */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/font/Athiti-ExtraLight.ttf") format('ttf');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/font/Athiti-ExtraLight.ttf") format('ttf');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/font/Athiti-ExtraLight.ttf") format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* thai */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/font/Athiti-Light.ttf") format('ttf');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* vietnamese */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/font/Athiti-Light.ttf") format('ttf');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/font/Athiti-Light.ttf") format('ttf');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/font/Athiti-Light.ttf") format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* thai */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/font/Athiti-Bold.ttf") format('ttf');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* vietnamese */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/font/Athiti-Bold.ttf") format('ttf');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/font/Athiti-Bold.ttf") format('ttf');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/font/Athiti-Bold.ttf") format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* thai */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/font/Athiti-SemiBold.ttf") format('ttf');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

/* vietnamese */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/font/Athiti-SemiBold.ttf") format('ttf');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/font/Athiti-SemiBold.ttf") format('ttf');
  unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Athiti';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/font/Athiti-SemiBold.ttf") format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  font-family: "Athiti", Athiti, athiti;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::selection {
  background: transparent;
}



